<template>
  <div class="loading-spinner">
    <div class="flex flex-center">
      <div class="kt-spinner kt-spinner--md kt-spinner--info"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader",
};
</script>

<style>
</style>